<template>
  <div>
    <p class="title sub-title">
      <span v-if="!isAdditional&&questionTotal>1">
        <i class="questionIndex"> {{ questionIndex + 1 }}</i>
        <i class="questionTotal">/{{ questionTotal }}</i>
      </span>
      <span v-else :alt="$t('questionaire.superadditionTopic')"></span>
      {{ questionData.question }}
      <b class="select-type">({{$t('questionaire.radio')}})</b>
      <b v-if="questionData.isOptional == 0" class="must-notice">*</b>
    </p>
    <div v-for="(questionOpt,index) in questionData.questionOpts" :key="index">
      <div class="flex-center" :class="{'f1':isEditable, 'f2':!isEditable, 'f1-active':questionOpt.isSelected===1&& isEditable, 'f2-active':questionOpt.isSelected===1&&!isEditable}" @click="qaItemSave(index)">
        <div class="circle" style="margin:0">
          <i :class="[{'active':questionOpt.isSelected===1}]" />
        </div>
        <span>{{ setabc(index) }}、{{ questionOpt.label }}
        </span>
      </div>
      <div style="margin-left: 30px">
<!--        <el-input-->
<!--          v-if="questionOpt.isSelected===1 && questionOpt.isInput===1 && questionOpt.isNumber===0"-->
<!--          v-model="questionOpt.inputAnswer"-->
<!--          type="text"-->
<!--          placeholder="说点什么"-->
<!--          :readonly="!isEditable"-->
<!--          @blur="afterUserInput(index)"-->
<!--        />-->
        <van-field
          v-if="questionOpt.isSelected===1 && questionOpt.isInput===1 && questionOpt.isNumber===0"
          class="custom-filed"
          v-model="questionOpt.inputAnswer"
          rows="1"
          autosize
          :readonly="!isEditable"
          type="textarea"
          :placeholder="$t('questionaire.desc')"
          @blur="afterUserInput()"
        />
        <van-field
          v-if="questionOpt.isSelected===1 && questionOpt.isInput===1 && questionOpt.isNumber===1"
          class="custom-filed"
          v-model="questionOpt.inputAnswer"
          rows="1"
          autosize
          :readonly="!isEditable"
          :type="questionOpt.isNumber==1?'number':'textarea'"
          :placeholder="questionOpt.questions"
          @blur="afterUserInput()"
        />
<!--        <el-input-->
<!--          v-if="questionOpt.isSelected===1 && questionOpt.isInput===1 && questionOpt.isNumber===1"-->
<!--          v-model="questionOpt.inputAnswer"-->
<!--          type="number"-->
<!--          placeholder="说点什么"-->
<!--          :readonly="!isEditable"-->
<!--          @blur="afterUserInput(index)"-->
<!--        />-->
      </div>
      <!-- {{questionOpt.isSelected}} -->
      <additional-questions
        v-if="questionOpt.isSelected"
        :master-question-data="questionData"
        :questions-data="questionOpt.additionalQuestions"
        :after-answered="afterAnswered"
        :is-preview="isPreview"
      />
    </div>
  </div>
</template>
<script>
import { generateBig_1 } from '@/utils/generate26Letter'
const AdditionalQuestions = () => import('./AdditionalQuestions')
export default {
  name: 'SingleChoiceQuestion',
  components: { AdditionalQuestions },
  props: {
    isPreview: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    isAdditional: {
      type: Boolean,
      default: false
    },
    questionData: Object,
    questionIndex: Number,
    questionTotal: Number,
    afterAnswered: Function
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    nothing() {

    },
    afterUserInput() {
      if (!this.isEditable) {
        return
      }
      this.afterAnswered(this.questionData)
    },
    qaItemSave(index) {
      if (!this.isEditable) {
        return
      }
      for (let i = 0; i < this.questionData.questionOpts.length; i++) {
        if (i === index) {
          this.questionData.questionOpts[i].isSelected = 1
        } else {
          this.questionData.questionOpts[i].isSelected = 0
        }
      }
      if(this.questionData.questionOpts[index].additionalQuestions.length == 0){
        this.afterAnswered(this.questionData);
      }
    },

    setabc(index) {
      const ary = generateBig_1()
      return ary[index]
    }
  }
}
</script>
<style lang="scss" scoped>
.sub-title {
  font-size: .6rem;
}
</style>
