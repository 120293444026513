<template>
  <div :class="[{isReadonly:questionarieData.isSubmitted===1},'page-cont']" id="questbox">
    <div v-if="questionarieData.isSubmitted===1&&$route.query.__inArticle__  != 1" class="submitted-tip">
      <span class="warning-img" />  <span style="padding-left: 0.25rem">{{$t("questionaire.warningText1")}}</span>
    </div>

    <div v-if="questionarieData.isPreview===1&&$route.query.__inArticle__  != 1" class="preview-tip">
      <span class="warning-img" />  <span style="padding-left: 0.25rem">{{$t("questionaire.warningText2")}}</span>
    </div>

    <div class="header-top" v-if="$route.query.__inArticle__ != 1">
    </div>
    <p v-show="questionarieData.notice" v-if="$route.query.__inArticle__ != 1" class="header-notice" ref="notice">{{ questionarieData.notice }}</p>

    <ul class="question" :style="{'padding-top':heightTop}">
      <li v-for="(item,index) in questionarieData.questions" :key="index">
        <single-choice-question
          v-show="item.type==102001"
          :question-data="item"
          :question-index="index"
          :question-total="questionarieData.questions.length"
          :after-answered="afterAnswered"
          :is-editable="questionarieData.isSubmitted!==1"
          :is-preview="questionarieData.isPreview===1"
        />
        <multiple-choice-question
          v-show="item.type==102002"
          :question-data="item"
          :question-index="index"
          :question-total="questionarieData.questions.length"
          :after-answered="afterAnswered"
          :is-editable="questionarieData.isSubmitted!==1"
          :is-preview="questionarieData.isPreview===1"
        />
        <text-question
          v-if="item.type==102003"
          :question-data="item"
          :question-index="index"
          :question-total="questionarieData.questions.length"
          :after-answered="afterAnswered"
          :is-editable="questionarieData.isSubmitted!==1"
          :is-preview="questionarieData.isPreview===1"
        />
        <picture-question
          v-if="item.type==102004"
          :question-data="item"
          :question-index="index"
          :question-total="questionarieData.questions.length"
          :after-answered="afterAnswered"
          :is-editable="questionarieData.isSubmitted!==1"
          :is-preview="questionarieData.isPreview===1"
        />
        <text-and-picture-question
          v-if="item.type==102005"
          :question-data="item"
          :question-index="index"
          :question-total="questionarieData.questions.length"
          :after-answered="afterAnswered"
          :is-editable="questionarieData.isSubmitted!==1"
          :is-preview="questionarieData.isPreview===1"
        />
        <vas5-question
          v-if="item.type==102006 && item.vasScore===5"
          :question-data="item"
          :question-index="index"
          :question-total="questionarieData.questions.length"
          :after-answered="afterAnswered"
          :is-editable="questionarieData.isSubmitted!==1"
          :is-preview="questionarieData.isPreview===1"
        />
        <vas10-question
          v-if="item.type==102006 && item.vasScore===10"
          :question-data="item"
          :question-index="index"
          :question-total="questionarieData.questions.length"
          :after-answered="afterAnswered"
          :is-editable="questionarieData.isSubmitted!==1"
          :is-preview="questionarieData.isPreview===1"
        />
        <survey-question
          v-if="item.type==102007"
          :question-data="item"
          :question-index="index"
          :question-total="questionarieData.questions.length"
          :after-answered="afterAnswered"
          :is-editable="questionarieData.isSubmitted!==1"
          :is-preview="questionarieData.isPreview===1"
        />
        <nps-question
          v-if="item.type==102008"
          :question-data="item"
          :question-index="index"
          :question-total="questionarieData.questions.length"
          :after-answered="afterAnswered"
          :is-editable="questionarieData.isSubmitted!==1"
          :is-preview="questionarieData.isPreview===1"
        />
        <vote-choice-questions
           v-if="item.type==102009"
          :question-data="item"
          :question-index="index"
          :question-total="questionarieData.questions.length"
          :after-answered="afterAnswered"
          :is-editable="questionarieData.isSubmitted!==1"
          :is-preview="questionarieData.isPreview===1"
        />
        <vote-multiple-choice-questions
          v-if="item.type==102010"
          :question-data="item"
          :question-index="index"
          :question-total="questionarieData.questions.length"
          :after-answered="afterAnswered"
          :is-editable="questionarieData.isSubmitted!==1"
          :is-preview="questionarieData.isPreview===1"
        />
      </li>
    </ul>
    <div class="question-explain" v-show="questionarieData.explain&&questionarieData.isSubmitted===1">
      <p>{{$t("pe.fullInterpretation")}}</p>
      <div> {{questionarieData.explain}}</div>
    </div>
    <div class="page-btn" v-if="questionarieData.questions&&questionarieData.questions.length!=0">
      <div class="submit-btn">
        <van-button class="ques-btn" v-if="questionarieData.isSubmitted==0||!$route.query.__inArticle__" round type="info" block :color="questionarieData.isSubmitted==0?'linear-gradient(#499FFF,#49A5FF,#49C1FF)':'#DDDFE5'" :class="questionarieData.isSubmitted!=0?'prohibit-btn':''" @click="submitQuestionaire">{{questionarieData.isSubmitted==0?$t("questionaire.Submit"):$t("questionaire.Submitted")}}
        </van-button>
      </div>
    </div>
    <dialog-login :overlay-show="loginOverlay" :filed="'loginOverlay'" ></dialog-login>
  </div>
</template>
<script>
import { generateBig_1 } from '@/utils/generate26Letter'

import SingleChoiceQuestion from './components/SingleChoiceQuestion'
import MultipleChoiceQuestion from '@/views/questionaire/components/MultipleChoiceQuestion'
import TextQuestion from './components/TextQuestion'
import TextAndPictureQuestion from './components/TextAndPictureQuestion'
import Vas10Question from './components/Vas10Question'
import Vas5Question from './components/Vas5Question'
import SurveyQuestion from './components/SurveyQuestion'
import NpsQuestion from './components/NpsQuestion'
import PictureQuestion from './components/PictureQuestion'
import voteChoiceQuestions from './components/voteChoiceQuestions.vue'

import dialogLogin from "@/components/dialoglogin/index";
import VoteMultipleChoiceQuestions from './components/voteMultipleChoiceQuestions.vue'
export default {
  name: 'Questionaire',

  components: {
    SingleChoiceQuestion,
    MultipleChoiceQuestion,
    TextQuestion,
    TextAndPictureQuestion,
    Vas10Question,
    Vas5Question,
    SurveyQuestion,
    NpsQuestion,
    PictureQuestion,
    dialogLogin,
    voteChoiceQuestions,
    VoteMultipleChoiceQuestions
  },
  data() {
    return {
      loginOverlay:false,
      screenHeight: document.body.clientHeight,
      originHeight: document.body.clientHeight,
      // questionarieData: json.data,
      questionarieData: {},
      isSubmit: 1,
      heightTop: null
    }
  },
  computed: {},
  watch: {
    screenHeight(val) {
      if (this.originHeight !== val) {
        this.isOriginHei = false
      } else {
        this.isOriginHei = true
      }
    }
  },
  created(){
    // alert(JSON.stringify(this.$route.query))
    this.getQuestionarieData()
  },
  mounted() {
    const self = this
    // window.onresize = () => (() => {
    //   window.screenHeight = document.body.clientHeight
    //   self.screenHeight = window.screenHeight
    // })()

    if(window.parent.length!=0){
      this.$nextTick(()=>{
        setTimeout(()=>{
          console.log(document.getElementsByClassName("question")[0].offsetHeight,document.getElementsByClassName('page-btn')[0].offsetHeight);
          // console.log(window,document.getElementsByClassName('page-cont')[0].offsetHeight);
          let height = document.getElementsByClassName("question")[0].offsetHeight+document.getElementsByClassName('page-btn')[0].offsetHeight;
          window.parent.parent.postMessage(JSON.stringify({HtmlfontSize:document.getElementsByTagName('html')[0].style.fontSize,windowWidth:document.body.offsetWidth ,domeHtml:height,className:'iframe'+(this.questionarieData.questionaireDefId)}),'*'); 
        },500)
      })
    }
  },
  destroyed() {
    const self = this
    clearTimeout(self.timer);
  },
  methods: {
 
    buildSubmitParam(question) {
      var opts = []
      if (question.questionOpts) {
        for (let i = 0; i < question.questionOpts.length; i++) {
          var opt = {
            questionOptId: question.questionOpts[i].id,
            isSelected: question.questionOpts[i].isSelected,
            inputAnswer: question.questionOpts[i].inputAnswer
          }

          if (question.questionOpts[i].additionalQuestions) {
            var additionalQuestions = []
            for (let k = 0; k < question.questionOpts[i].additionalQuestions.length; k++) {
              additionalQuestions.push(this.buildSubmitParam(question.questionOpts[i].additionalQuestions[k]))
            }

            opt.additionalQuestions = additionalQuestions
          }

          opts.push(opt)
        }
      }

      var param = {
        questionId: question.id,
        inputAnswer: question.inputAnswer,
        surveyAdditional: question.surveyAdditional,
        imgUrlsAnswer: question.imgUrlsAnswer,
        questionOpts: opts
      }

      return param
    },
    async afterAnswered(question) {
      if (this.questionarieData.isPreview === 1 || this.questionarieData.isSubmitted === 1) {
        return
      }
      question.showMustNotice = false;
      question.isSubmitted = 1;
      setTimeout(()=>{
        let height = document.getElementsByClassName("question")[0].offsetHeight+document.getElementsByClassName('page-btn')[0].offsetHeight;
          window.parent.parent.postMessage(JSON.stringify({HtmlfontSize:document.getElementsByTagName('html')[0].style.fontSize,windowWidth:document.body.offsetWidth ,domeHtml:height,className:'iframe'+(this.questionarieData.questionaireDefId)}),'*'); 
      },100)
      // var resData = await this.$axios1.post('/questionaire/question-submit', this.buildSubmitParam(question))
      // if (resData.data.isSubmitted === 1) {
      // }
    },
    async getQuestionarieData() {
      let url = '/questionaire2/create-or-get';
      // if(this.$route.query['__hospPreview__']){
      //   url = '/questionaire/preview';
      // }
      // console.log(this.$route);
      const toast = this.$Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: this.$t("message.loadingTxt")
      });
      const res = await this.$axios1.post(url, {
        questionaireId: this.$route.query.questionaireId,
        questionaireDefId: this.$route.query.questionaireDefId||this.$route.query.previewQuestionaireId,
        msgPushId: this.$route.query._msg_push_id_,
        eventId: this.$route.query._event_id_
        
      })
      toast.clear();
      let resData ;
      if(res.data){
        resData = JSON.parse(JSON.stringify(res.data));
      }
      if(res.errorCode == 0){
        if(res.data.id > 0){
          let query = this.$route.query;
          query.questionaireId = res.data.id;
          query.questionaireDefId = this.$route.query.questionaireDefId;
          query.msgPushId = this.$route.query._msg_push_id_;
          query.eventId = this.$route.query._event_id_;
          query.__inArticle__ = this.$route.query.__inArticle__;
          this.$router.push({
            query:query
          })
        }
        
      }
      res.data.questions.forEach((item, index) => {
        item.showMustNotice = false
      })
      document.title = res.data.title;
      this.questionarieData = res.data;
      if(this.$route.query.__fromLogin__ == 1){
        this.submitQuestionaire(resData);
      }
    },
    async submitQuestionaire(data) {
      if(this.$route.query.__fromLogin__ != 1){
        if (this.questionarieData.isSubmitted === 1 || this.questionarieData.isPreview === 1) {
          if (this.questionarieData.isPreview === 1 && this.questionarieData.isSubmitted === 0) {
            this.$toast(this.$t("questionaire.previewNotsubmit"))
          }
          return
        }

        let ret = false
        for (const item of this.questionarieData.questions) {
          // const index = this.questionarieData.questions.indexOf(item)
          if (item.isSubmitted !== 1) {
            if (item.isOptional == 1) {
              item.inputAnswer = 0
              await this.afterAnswered(item)
            } else {
              item.showMustNotice = true
              ret = true
            }
          }
        }
        if (ret) {
          this.$toast(this.$t("questionaire.requrieMsg"))
          return
        }
      }
      const toast = this.$Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: this.$t("message.loadingTxt")
			});	

      let resData = await this.$axios1.post('/questionaire2/submit',this.$route.query.__fromLogin__ == 1?data: this.questionarieData)
      toast.clear();
      if (resData.errorCode == 0) {
        if(this.$route.query.__chat_id__){
          const res = await this.$imapiAxio.post(
            '/im/msg-send',
            {
              chatId: this.$route.query.__chat_id__,
              sessionId: !this.$route.query.__session_id__?null:this.$route.query.__session_id__,
              msgBody: {
                type:"share",
                content:{
                  desc:resData.data.title,
                  title:"问卷已提交，请您查看！",
                  url:resData.data.url,
                  imgUrl: "http://img.11zhushou.com/cxo/png/hosp/iv_msg_qn.png"
                }
              },
              msgSummary: '【问卷】',
              sessionEnv: {}
            }
          )
        }
        if(this.$route.query.__inArticle__ == 1){
            if(resData.data.id > 0){
              let query = this.$route.query;
              query.questionaireId = resData.data.id;
              query.questionaireDefId = this.$route.query.questionaireDefId;
              query.msgPushId = this.$route.query._msg_push_id_;
              query.eventId = this.$route.query._event_id_;
              query.__inArticle__ = this.$route.query.__inArticle__;
              this.$router.push({
                query:query
              })
            }
          resData.data.questions.forEach((item, index) => {
            item.showMustNotice = false
          })
          document.title = resData.data.title;
          this.questionarieData = resData.data
          setTimeout(()=>{
            let height = document.getElementsByClassName("question")[0].offsetHeight+document.getElementsByClassName('page-btn')[0].offsetHeight;
            //  文章内嵌 问卷时 ifream 之间 相互通信
            window.parent.parent.postMessage(JSON.stringify({HtmlfontSize:document.getElementsByTagName('html')[0].style.fontSize,windowWidth:document.body.offsetWidth ,domeHtml:height,className:'iframe'+(this.questionarieData.questionaireDefId)}),'*'); 
          },100)
        }else{
          this.$router.replace('/questionaire/after')
        }
      }else if( resData.errorCode == -401){
        if(this.$route.query.__inArticle__ == 1){
          //  文章内嵌 问卷时 ifream 之间 相互通信
          window.parent.parent.postMessage(JSON.stringify({type:'login'}),'*'); 
        }else{
          this.loginOverlay = true;
        }
      }
    },
    setabc(index) {
      const ary = generateBig_1()
      return ary[index]
    }
  }
}
</script>
<style lang='scss'>

.page-cont {
  min-height: 100%;
  //pointer-events: none;//禁止点击
  .submitted-tip{
    background: #000000;
    opacity: 0.6;
    color:#fff;
    font-size:.6rem;
    padding-top:0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 1rem;
    width:100%;
    max-width: 750px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    position: fixed;
    top:0.5rem;
    z-index: 100;
    .warning-img{
      width: 1rem;
      height:1rem;
      background: url("../../assets/images/notice.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .preview-tip{
    background: #FF0000;
    opacity: 0.7;
    color:#fff;
    font-size:.6rem;
    padding-top:0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 1rem;
    width:100%;
    max-width: 750px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    position: fixed;
    top:.5rem;
    z-index: 100;
    .warning-img{
      width: 1rem;
      height:1rem;
      background: url("../../assets/images/warning.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .header-top {
    position: relative;
    width: 100%;
    height:10rem;
    background: url("../../assets/images/sys/qusHeader.png") no-repeat;
    background-size: 100% 100%;
    padding-bottom:1rem;
    z-index: -1;
  }
  .header-notice {
    display: block;
    color: #FF671C;
    width: 84%;
    margin-left:8%;
    padding: .5rem;
    box-sizing: border-box;
    border-radius: .6rem;
    background: #fff;
    font-size: .6rem;
    box-shadow: 0 0 2rem 0 rgba(37, 102, 164, .25);
    margin-top:-1.5rem;
  }

  .question {
    padding-bottom: 1rem;
    padding-top:1rem;
  }

  .page-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 1.5rem;
    padding-top:1rem;

    .submit-btn {
      width: 60%;
      button{
        padding: 0.7rem 0;
      }
    }
  }

}

.question {
  width: 100%;
  height: 100%;

  li {
    margin: 0.25rem 1rem;
    background: #fff;
    padding-bottom: 0.25rem;
    .noeditbox{
      background: #F5F6FA;
      padding: 0.6rem;
    }
    p.title {
      padding-bottom: .5rem;
      font-size: 0.72rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      .questionIndex {
        font-style: normal;
        font-size: 0.9rem;
        color:  #333333;
      }
      .questionTotal {
        font-style: normal;
        font-size: 0.5rem;
        color: #999;
      }

      .select-type {
        color: #999;
        font-size: 0.5rem;
        font-weight: normal;
      }
    }

    .img-tip {
      color: #dcdcdc;
      border: 0.05rem solid #dcdcdc;
    }

    .rm {
      color: #999999;
      font-size: 1.6rem;
      border-bottom: none;
      display: inline-block;
      margin: 0 1rem 0 3rem;
      padding: 0 0.5rem;
    }

    .f1 {
      display: flex;
      color: #666;
      font-size: 0.6rem;
      /*flex-direction: row;*/
      /*align-items: center;*/
      /*align-content: space-around;*/
      line-height: 1rem;
      padding-bottom: 0.5rem;

      .circle {
        width: 0.8rem;
        height: 0.8rem;
        margin-right: 0.5rem;

        i {
          display: inline-block;
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 50%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          background: url("../../assets/images/inputState/default.png") no-repeat;
          background-size:cover ;

          .sel-active {
            width: 0.45rem;
            height: 0.45rem;
            // background: #fff;
            border-radius: 50%;
          }

        }

        .active {
          background: url("../../assets/images/inputState/select.png") no-repeat;
          background-size:cover ;
        }
        .isDisabled{
          background: url("../../assets/images/inputState/disable.png") no-repeat;
          background-size:cover ;
        }

        .ck {
          border-radius: 0.5rem;
        }
      }

      span {
        padding-left: 0.25rem;
      }
    }

    .f1-active {
      color: #3387FD;
    }

    .f2 {
      display: flex;
      color: #666;
      font-size: 0.6rem;
      /*flex-direction: row;*/
      /*align-items: center;*/
      /*align-content: space-around;*/
      padding-bottom: 0.5rem;

      .circle {
        width: 0.8rem;
        height: 0.8rem;
        margin-right: 0.5rem;

        i {
          display: inline-block;
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 50%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          background: url("../../assets/images/inputState/default.png") no-repeat;
          background-size:cover ;

        }

        .active {
          background: url("../../assets/images/inputState/disable.png") no-repeat;
          background-size:cover ;
        }

        .ck {
          border-radius: 0.5rem;
        }
      }

      span {
        padding-left: 0.25rem;
      }
    }

    .f2-active {
      color: #666666;
    }

    label {
    }

    input {
      width: 90%;
      padding: 0.25rem;
      font-size: 0.6rem;
      background: #F5F6FA;
      border-radius: 0.4rem;
    }

    .tp-textarea {
      width: 100%;
      height: 7.5rem;
      border: 0.1rem solid #f1f1f1;
    }

    .tp-input {
      width: 100%;
      background: #F5F6FA;
    }
  }
}

.weui-cell {
  font-size: 1.7rem;
}

.upload-img ul li div {
  margin: 0;
}

.picture-list .item {
  padding-right: 0;
}

.tip {
  padding: 0 2rem;
}

.tip1 {
  display: block;
  padding: 1rem;
}

.select-type {

}

// .sub-explain {
//   width: 100%;
//   height: 540px;
//   background: #F9F9F9;
//   color: #999;
//   font-size: 28px;
//   position: fixed;
//   bottom: 140px;
//   left: 0;
//   padding: 10px 20px;

//   .sub-title {
//     text-align: center;
//     width: 100%;
//     height: 50px;
//     line-height: 50px;
//     font-size: 36px;
//     color: #333;
//   }

//   .exp {
//     overflow: auto;
//     width: 100%;
//     // height: 80%;
//   }
// }
// .sub {
//   width: 100%;
//   height: 140px;
//   background: #fbf8fb;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   position: fixed;
//   bottom: 0;
//   left: 0;
// }

// .submit {
//   width: 80%;
//   height: 90px;
//   background: #3E93EA;
//   border-radius: 10px;
//   color: #fff;
//   font-size: 40px;
//   text-align: center;
//   line-height: 90px;
// }

// .explain {
//   font-size: 32px;
//   padding: 25px 20px;
//   color: #fe8c2b;
// }

// .check-explain {
//   font-size: 32px;
//   padding: 25px 20px;
//   color: #fe8c2b;
//   margin-left: 60px;
// }

// .feshu {
//   padding-top: 10px;

//   li {
//     font-size: 32px;
//   }
// }
.isReadonly{
  pointer-events: none;
  cursor: default;
}

.must-notice{
  color: red;
}
.flex-center{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.vote-result{
  margin-top: 0.6rem;
  .title{
    font-size: 0.56rem;
    font-weight: 400;
    color: #303133;
    line-height: 0.84rem;
    padding-bottom: 0.2rem !important;
  }
  .result-details{
    display: flex;
    align-items: center;
    .result-text{
      margin-left: 1.2rem;
      flex: auto 0 0;
      width: max-content;
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      // min-width: 4rem;
      span{
        font-size: 0.48rem;
        font-weight: 400;
        color: #909399;
        line-height: 0.84rem;
        min-width: 1.5rem;
        display: inline-block;
        text-align: right;
        &:last-child{
          margin-left: 0.8rem;
        }
      }
    }
    .van-slider{
      flex: 1;
    }
    .van-slider__button-wrapper{
      display: none;
    }
  }
}
.custom-filed{
  background: #F5F6FA;
  padding:0.25rem;
  font-size:.6rem;
  color:#666666;
  border-radius: 0.25rem;
  margin-bottom:0.5rem;
}
</style>
<style lang="scss" scoped>
.ques-btn{
  font-size:0.8rem;
  box-shadow: 0 0.25rem 0.4rem #D3EAFF;
}
.prohibit-btn{
  box-shadow: none;
}
.question-explain{
  p{
    color:#5C9DF8;
    font-size:0.7rem;
    height: 2rem;
    line-height: 2rem;
    background: url("../../assets/images/explain.png") no-repeat;
    background-size: cover;
    padding-left:0.5rem;
    margin-left:1rem;
    margin-right:1rem;
  }
  div{
    font-size:0.5rem;
    color:#5C9DF8;
    background: #F3F9FF;
    padding:0.5rem;
    margin-left:1rem;
    margin-right:1rem;
  }
}

</style>
