<template>
  <div>
    <p class="title">
      <span v-if="!isAdditional&&questionTotal>1">
        <i class="questionIndex"> {{ questionIndex + 1 }}</i>
        <i class="questionTotal">/{{ questionTotal }}</i>
      </span>
      <span v-else :alt="$t('questionaire.superadditionTopic')"></span>
      {{ questionData.question }}
      <b class="select-type">({{$t("questionaire.photo")}})</b>
      <b v-if="questionData.isOptional == 0" class="must-notice">*</b>
    </p>
    <div class="log-form-group">
      <upload-img :files="imgFiles" :readonly="!isEditable" @imgList="afterUpload" />
    </div>
  </div>
</template>
<script>
import uploadImg from '@/components/selfUploadImg'

export default {
  name: 'PictureQuestion',

  components: { uploadImg },
  props: {
    isPreview: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    isAdditional: {
      type: Boolean,
      default: false
    },
    questionData: Object,
    questionIndex: Number,
    questionTotal: Number,
    afterAnswered: Function
  },
  data() {
    return {
      imgFiles: []
    }
  },
  created() {
    if (this.questionData.imgUrlsAnswer) {
      for (var i = 0; i < this.questionData.imgUrlsAnswer.length; i++) {
        this.imgFiles.push({
          url: this.questionData.imgUrlsAnswer[i]
        })
      }
    }
  },
  methods: {
    afterUpload(data) {
      if (!this.isEditable) {
        return
      }
      this.questionData.imgUrlsAnswer = []
      if (data) {
        for (var i = 0; i < data.length; i++) {
          this.questionData.imgUrlsAnswer.push(data[i].url)
        }
      }

      this.afterAnswered(this.questionData)
    }
  }
}
</script>
<style lang="scss">
.edit {
  width: 95%;
  background: #F5F6FA;
  padding: .25rem;
  height: auto;
  overflow-x: visible;
  overflow-y: visible;
  font-size: .6rem;
}
</style>
