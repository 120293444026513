<template>
  <div>
    <p class="title">
      <span v-if="!isAdditional&&questionTotal>1">
        <i class="questionIndex"> {{ questionIndex + 1 }}</i>
        <i class="questionTotal">/{{ questionTotal }}</i>
      </span>
      <span v-else></span>
      {{ questionData.question }}
      <b v-if="questionData.isOptional == 0" class="must-notice">*</b>
    </p>
    <div class="nps-tip">
      {{$t("questionaire.scoreDescribe")}}
    </div>
    <div class="nps-outer">
      <p class="nps-yy"><span>{{$t("questionaire.veryDissatisfied")}}</span><span>{{$t("questionaire.verySatisfaction")}}</span></p>
      <ul class="qn-nps-list">
        <li v-for="(item,i) in (11)" :key="i" :class="[{'active':questionData.inputAnswer == i}]" @click="activeStar(item,i)">
          <span class="circle">{{ i }}</span>
        </li>
      </ul>
    </div>
  </div>

</template>
<script>
export default {
  name: 'NpsQuestion',

  props: {
    isPreview: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    isAdditional: {
      type: Boolean,
      default: false
    },
    questionData: Object,
    questionIndex: Number,
    questionTotal: Number,
    afterAnswered: Function
    // isSubmit: Number
  },
  data() {
    return {
      isSubmit: 1
    }
  },
  created(){
    this.activeStar('',0)
  },
  mounted() {
    // this.activeStar()
  },
  methods: {
    activeStar(item, index) {
      if (!this.isEditable) {
        return
      }
      this.questionData.inputAnswer = index
      this.afterAnswered(this.questionData)
    }
  }
}
</script>

<style lang='scss' scoped>
.nps-tip{
  border-bottom:.05rem solid #eaeaea;
  color:#666;
  margin-bottom:.5rem;
  font-size:.6rem;
  padding-bottom:.5rem;
}
.nps-outer {
  width: 100%;
  .nps-yy{
    margin-bottom:0.25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span{
      background: #ECEEF3;
      border-radius: 0.1rem;
      padding:0.1rem .25rem;
      color:#999999;
      font-size:.5rem;
      position: relative;
      &:last-child::before{
        content: ' ';
        border-left: .3rem solid transparent;
        border-right: .3rem solid transparent;
        border-top: .3rem solid #ECEEF3;
        bottom: -0.16rem;
        right: 0.2rem;
        position: absolute;
      }
      &:first-child::before{
        content: ' ';
        border-left: .3rem solid transparent;
        border-right: .3rem solid transparent;
        border-top: .3rem solid #ECEEF3;
        bottom: -0.16rem;
        left: 0.2rem;
        position: absolute;
      }
    }
  }
  .qn-nps-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    li {
      width:1rem;
      height:1rem;
      text-align: center;
      line-height: 1rem;
      border-radius: .25rem;
      border-top: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: #ECEEF3;
      padding: 0;
      margin: 0;
      color:#666666
    }
    .active {
      color:#fff;
      background: #3387FD;
    }
  }

  .nps-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .5rem;
    span {
      cursor: pointer;
      font-size:0.4rem;
      background: #ECEEF3;
      color: #999999;
    }
  }
  .click-ev {
    position: absolute;
    width: 75%;
    left: 6.5rem;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    li {
      height: 3rem;
    }
  }
}
</style>
