<template>
  <div>
    <p class="title">
      <span v-if="!isAdditional&&questionTotal>1">
        <i class="questionIndex"> {{ questionIndex + 1 }}</i>
        <i class="questionTotal">/{{ questionTotal }}</i>
      </span>
      <span v-else :alt="$t('questionaire.superadditionTopic')"></span>
      {{ questionData.question }}
      <b class="select-type">({{$t("questionaire.text")}})</b>
      <b v-if="questionData.isOptional == 0" class="must-notice">*</b>
    </p>
    <van-field
      class="custom-filed"
      v-model="questionData.inputAnswer"
      rows="1"
      autosize
      :readonly="!isEditable"
      :type="questionData.isNumber == 1?'number':'textarea'"
      :placeholder="questionData.question"
      @blur="inputItem"
    />
  </div>
</template>
<script>
import editDiv from '@/components/selfInput'
export default {
  name: 'TextQuestion',
  components: { editDiv },
  props: {
    isPreview: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    isAdditional: {
      type: Boolean,
      default: false
    },
    questionData: Object,
    questionIndex: Number,
    questionTotal: Number,
    afterAnswered: Function
  },
  data() {
    return{

    }
  },
  methods: {
    inputItem() {
      if (!this.isEditable) {
        return
      }
      this.afterAnswered(this.questionData)
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-filed{
  background: #F5F6FA;
  padding:.25rem;
  font-size:.6rem;
  color:#666666;
  border-radius: .25rem;
}
.edit{
  width:95%;
  background: #F5F6FA;
  padding:.25rem;
  height:auto;
  overflow-x:visible;
  overflow-y:visible;
  font-size:.6rem;
}
</style>
