<template>
  <div>
    <p class="title sub-title">
      <span v-if="!isAdditional&&questionTotal>1">
        <i class="questionIndex"> {{ questionIndex + 1 }}</i>
        <i class="questionTotal">/{{ questionTotal }}</i>
      </span>
      <span v-else :alt="$t('questionaire.superadditionTopic')"></span>
      {{ questionData.question }}
      <b class="select-type">({{$t("questionaire.multipleChoice")}})</b>
      <b v-if="questionData.isOptional == 0" class="must-notice">*</b>
    </p>
    <div v-for="(questionOpt,index) in questionData.questionOpts" :key="index">
      <div class="flex-center" :class="{'f1':isEditable, 'f2':!isEditable, 'f1-active':questionOpt.isSelected===1&& isEditable, 'f2-active':questionOpt.isSelected===1&&!isEditable}" @click="getcheckoutVal(questionOpt, index)">
        <div class="circle" style="margin:0">
          <i :class="questionOpt.isSelected===1?'active':''">
            <a v-show="questionOpt.isSelected===1" class="sel-active" href="javascript:void(0)" />
          </i>
        </div>
        <span>{{ setabc(index) }}、{{ questionOpt.label }}
        </span>
      </div>
      <div style="margin-left: 20px">
        <van-field
          v-if="questionOpt.isSelected===1 && questionOpt.isInput===1 && questionOpt.isNumber===0"
          class="custom-filed"
          v-model="questionOpt.inputAnswer"
          rows="1"
          autosize
          :readonly="!isEditable"
          type="textarea"
          :placeholder="$t('questionaire.desc')"
          @blur="afterUserInput()"
        />
        <van-field
          v-if="questionOpt.isSelected===1 && questionOpt.isInput===1 && questionOpt.isNumber===1"
          class="custom-filed"
          v-model="questionOpt.inputAnswer"
          rows="1"
          autosize
          :readonly="!isEditable"
          :type="questionOpt.isNumber==1?'number':'textarea'"
          :placeholder="questionOpt.questions"
          @blur="afterUserInput()"
        />
      </div>
      <additional-questions
        v-if="questionOpt.isSelected"
        :master-question-data="questionData"
        :questions-data="questionOpt.additionalQuestions"
        :after-answered="afterAnswered"
        :is-preview="isPreview"
        :is-editable="isEditable"
      />
    </div>
  </div>
</template>
<script>
import { generateBig_1 } from '@/utils/generate26Letter'
// import AdditionalQuestions from './AdditionalQuestions'
const AdditionalQuestions = () => import('./AdditionalQuestions')
export default {
  name: 'MultipleChoiceQuestion',
  components: { AdditionalQuestions },
  props: {
    isPreview: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    isAdditional: {
      type: Boolean,
      default: false
    },
    questionData: Object,
    questionIndex: Number,
    questionTotal: Number,
    afterAnswered: Function
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    afterUserInput() {
      if (!this.isEditable) {
        return
      }
      this.afterAnswered(this.questionData)
    },
    getcheckoutVal(questionOpt) {
      if (!this.isEditable) {
        return
      }
      questionOpt.isSelected === 0 ? questionOpt.isSelected = 1 : questionOpt.isSelected = 0
      this.afterAnswered(this.questionData)
    },

    setabc(index) {
      const ary = generateBig_1()
      return ary[index]
    }
  }
}
</script>
<!--<style scoped>-->
<!--.custom-filed{-->
<!--  background: #F5F6FA;-->
<!--  padding:5px;-->
<!--  font-size:12px;-->
<!--  color:#666666;-->
<!--  border-radius: 5px;-->
<!--  margin-bottom:10px;-->
<!--}-->
<!--</style>-->
