
<template>
  <van-uploader
    v-model="fileList"
    multiple
    :max-size="10000 * 1024"
    :after-read="afterRead"
    :disabled="readonly"
    :deletable="!readonly"
    @oversize="onOversize"
    @delete="deleteItem"
  >
    <div class="upload-cover">
      <img src="../../assets/images/sys/upload-icon.png" alt="">
       <p>{{$t("comonTxt.uploadImg")}}</p>
      </div>
  </van-uploader>
</template>
<script>
import { Toast } from 'vant'

export default {
  props: {
    files: Array,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileList: []
    }
  },
  mounted() {
    this.fileList = this.files == null ? [] : this.files
  },
  methods: {
    afterRead(file) {
      if (file.file.type !== 'image/png' && file.file.type !== 'image/jpeg' && file.file.type !== 'image/jpg') {
        Toast.fail(this.$t("comonTxt.uploadType"))
      } else {
        file.status = 'uploading'
        file.message = this.$t("comonTxt.uploading")
        this.uploadAxios({ data: file.content, fileName: file.file.name }, file)
      }
    },
    onOversize(file) {
      Toast.fail(this.$t("comonTxt.uploadsize"))
    },
    uploadAxios(data, file) {
      this.$uploadAxios.post('upload/base64-upload', data).then(res => {
        file.message = this.$t("comonTxt.uploadOver")
        file.status = 'done'
        file.url = res.data
        this.$emit('imgList', this.fileList)
      })
    },
    deleteItem() {
      this.$emit('imgList', this.fileList)
    }
  }
}
</script>
<style lang="scss" scoped>
.upload-cover{
  width:4rem;
  height:4rem;
  border-radius: 0.05rem;
  background:#EDEEF2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img{
    width:1.5rem;
    height:1.5rem;
  }
  p{
    color:#999999;
    font-size:0.5rem;
    padding-top:0.25rem;
  }
}
</style>
