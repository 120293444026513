export function generateBig_1() {
  const str = [];
  for (let i = 65; i < 91; i++) {
    str.push(String.fromCharCode(i));
  }
  return str;
}

export function generateSmall_1() {
  const str = [];
  for (let i = 97; i < 123; i++) {
    str.push(String.fromCharCode(i));
  }
  return str;
}
