<template>
  <div class="box"
       contenteditable="true"
       v-html="innerText"
       v-focus="editFocus"
       style="-webkit-user-select:text"
       :placeholder='$t("message.saySomething")'
       @input="changeTxt"
       @focus="keyboard"
       @blur="afterInput"
       ref="test"
  ></div>
</template>
<script>
export default {
  props: {
    innerTxt: {
      type: String
    },
    editFocus: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      innerText: this.innerTxt,
      lock: false
    }
  },
  watch:{
    innerTxt:{
      handler(newValue, oldValue) {
        if(!this.lock) {
          this.innerText = this.innerTxt;
        };
      },
      deep:true
    }
  },
  methods:{
    keyboard(){
      if(/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)){
        document.body.scrollTop = document.body.scrollHeight;
      }
      this.lock=true

    },

    changeTxt(e) {
      this.innerText = this.$refs.test.innerHTML
    },
    afterInput(){
      // console.log(this.innerText)
    }
  },
  //自定义指令
  directives: {
    focus: {
      update(el ,{value}) {
        if (value) {
          if (window.getSelection) {//ie11 10 9 ff safari
            el.focus(); //解决ff不获取焦点无法定位问题
            var range = window.getSelection();//创建range
            range.selectAllChildren(el);//range 选择obj下所有子内容
            range.collapseToEnd();//光标移至最后
          }
          else if (document.selection) {//ie10 9 8 7 6 5
            var range = document.selection.createRange();//创建选择对象
            range.moveToElementText(el);//range定位到obj
            range.collapse(true);//光标移至最后
            range.select();
          }
        }
        else{
          el.blur();
        }

      }
    }
  },
}
</script>
<style scoped>
.box{
  font-size: 0.6rem;
  background:#F5F6FA;
  padding:0.25rem;
}
.box:empty:before{
  content:attr(placeholder);
  color:#c7c7c7;
}
.box:focus:before{
  content:none;
}
</style>
