<template>
  <div>
    <p class="title">
      <span v-if="!isAdditional&&questionTotal>1">
        <i class="questionIndex"> {{ questionIndex + 1 }}</i>
        <i class="questionTotal">/{{ questionTotal }}</i>
      </span>
      <span v-else :alt="$t('questionaire.superadditionTopic')"></span>
      {{ questionData.question }}
      <b v-if="questionData.isOptional == 0" class="must-notice">*</b>
    </p>
    <div class="face-outer">
      <ul class="face-list">
        <li v-for="(item,index) in starList" :key="item.key" @click="select(index)">
          <img :src="item.active==0?item.img:item.actImg" alt="">
          <p :class="[{'face-active':item.active!=0},'face-text']">{{ item.faceText }}</p>
        </li>
      </ul>
    </div>
    <div v-if="questionData.inputAnswer<=questionData.surveyLowScore && showAdditional">
      <div class="surveyLowScoreQuestion">
        {{ questionData.surveyLowScoreQuestion }}
      </div>
      <div class="inputOptions">
        <span v-for="tagItem in tags"  :key="tagItem.key" :class=" questionData.surveyAdditional.indexOf(tagItem.tag)>-1?'inputOptionSelected':'inputOption'" @click="insertTagText(tagItem)">
          {{ tagItem.tag }}
        </span>
      </div>
<!--      <textarea id="" v-model="questionData.surveyAdditional" class="edit" name="" :readonly="!isEditable" @blur="afterInput" />-->

      <van-field
        class="custom-filed"
        v-model="questionData.surveyAdditional"
        rows="1"
        autosize
        :readonly="!isEditable"
        type="textarea"
        :placeholder="$t('questionaire.desc')"
        @blur="afterInput"
        ref="inputVal"
      />
    </div>
  </div>

</template>
<script>
import fcbmy from '@/assets/images/sys/fcbmy.png'
import bmy from '@/assets/images/sys/bmy.png'
import yb from '@/assets/images/sys/yb.png'
import my from '@/assets/images/sys/my.png'
import fcmy from '@/assets/images/sys/fcmy.png'
import fcbmyActive from '@/assets/images/sys/fcbmy-a.png'
import bmyActive from '@/assets/images/sys/bmy-a.png'
import ybActive from '@/assets/images/sys/yb-a.png'
import myActive from '@/assets/images/sys/my-a.png'
import fcmActive from '@/assets/images/sys/fcmy-a.png'
export default {
  name: 'SurveyQuestion',
  props: {
    isPreview: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    isAdditional: {
      type: Boolean,
      default: false
    },
    questionData: Object,
    questionIndex: Number,
    questionTotal: Number,
    afterAnswered: Function
  },
  data() {
    return {
      starList: [
        {
          key: 1,
          active: 0,
          img: fcbmy,
          actImg: fcbmyActive,
          faceText: this.$t("questionaire.veryDissatisfied")
        },
        {
          key: 2,
          active: 0,
          img: bmy,
          actImg: bmyActive,
          faceText:  this.$t("questionaire.dissatisfied")
        },
        {
          key: 3,
          active: 0,
          img: yb,
          actImg: ybActive,
          faceText: this.$t("questionaire.commonly")
        },
        {
          key: 4,
          active: 0,
          img: my,
          actImg: myActive,
          faceText: this.$t("questionaire.satisfied")
        },
        {
          key: 5,
          active: 0,
          img: fcmy,
          actImg: fcmActive,
          faceText: this.$t("questionaire.verySatisfaction")
        }
      ],
      tags: [],
      count: 0
    }
  },
  computed: {
    showAdditional() {
      var a = false
      this.starList.forEach((item, index) => {
        if (item.active === 1) {
          a = true
        }
      })

      return a
    }
  },
  created() {
    if (this.questionData.inputAnswer && this.questionData.inputAnswer > 0) {
      this.starList.forEach((item, index) => {
        if (this.questionData.inputAnswer - 1 === index) {
          item.active = 1
        } else {
          item.active = 0
        }
      })
    }

    if (this.questionData.inputOptions) {
      this.questionData.inputOptions.forEach((item, index) => {
        this.tags.push({
          key: index,
          tag: item,
          selected: this.questionData.surveyAdditional.indexOf(item)>-1?true:false
        })
      })
    }
  },
  methods: {
    insertTagText(tagItem) {
      tagItem.selected = !tagItem.selected
      if (tagItem.selected) {
        if (this.questionData.surveyAdditional) {
          if (this.questionData.surveyAdditional.indexOf(tagItem.tag) < 0) {
            this.questionData.surveyAdditional = this.questionData.surveyAdditional + '，' + tagItem.tag
          }
        } else {
          this.questionData.surveyAdditional = tagItem.tag
        }
      } else {
        if (this.questionData.surveyAdditional) {
          this.questionData.surveyAdditional = this.questionData.surveyAdditional.replace(tagItem.tag + '，', '')
          this.questionData.surveyAdditional = this.questionData.surveyAdditional.replace('，' + tagItem.tag, '')
          this.questionData.surveyAdditional = this.questionData.surveyAdditional.replace(tagItem.tag, '')
        }
      }
      this.$nextTick(function () {
        this.$refs.inputVal.focus()
      })
    },
    afterInput() {
      if (!this.isEditable) {
        return
      }
      this.afterAnswered(this.questionData)
    },
    select(i) {
      if (!this.isEditable) {
        return
      }
      this.starList.forEach((item, index) => {
        if (i === index) {
          item.active = 1
        } else {
          item.active = 0
        }
      })
      this.questionData.inputAnswer = (i + 1)
      this.afterAnswered(this.questionData)
    }
  }

}
</script>
<style lang="scss" scoped>
.custom-filed{
  background: #F5F6FA;
  padding:.25rem;
  font-size:.6rem;
  color:#666666;
  border-radius: .25rem;
}
.inputOption{
  color: #838385;
  border: .05rem solid #838385;
}
.inputOptions span{
  display: inline-block;
  font-size: .5rem;
  border-radius: .2rem;
  margin-right: .25rem;
  font-family: PingFang SC;
  padding:.1rem .15rem;
  margin-bottom:.15rem;
  &:last-child{
    margin-right: 0;
  }

}
.inputOptions{
  padding:.25rem 0;
  margin-bottom:.25rem;
}
.inputOptionSelected{
  border: .05rem solid #FF671C;
  color: #FF671C;
}
.surveyLowScoreQuestion{
  padding-left: .1rem;
  font-size:.6rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FF671C;
  line-height: 1.25rem;
  margin-bottom:.25rem;
  padding-top:.5rem;
}

.face-outer {
  display: flex;
  flex-direction: row;
  align-items: center;

  .face-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    li {
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: none;
      img {
        width: 1.5rem;
        height: 1.5rem;
      }

      .face-text {
        font-size: .5rem;
        color: #666666;
        margin-top: .5rem;
      }
      .face-active{
        color:#3387FD
      }
    }
  }

  span {
    font-size: .7rem;
  }

  .fcbmy {
    color: #f43961;
    font-weight: 600;
  }

  .fcmy {
    color: #268ef6;
    font-weight: 600;
  }

}
</style>
