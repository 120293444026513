<template>
    <div :class="{'noeditbox':!isEditable&&$route.query.__inArticle__}">
        <p class="title">
            <span v-if="!isAdditional&&questionTotal>1">
                <i class="questionIndex"> {{ questionIndex + 1 }}</i>
                <i class="questionTotal">/{{ questionTotal }}</i>
            </span>
            {{ questionData.question }}
            <b class="select-type">({{$t("questionaire.radio")}})</b>
            <b v-if="questionData.isOptional == 0" class="must-notice">*</b>
        </p>
        <div v-if="isEditable">
            <div v-for="(questionOpt,index) in questionData.questionOpts" :key="index">
                <div class="flex-center" :class="{'f1':isEditable, 'f2':!isEditable, 'f1-active':questionOpt.isSelected===1&& isEditable, 'f2-active':questionOpt.isSelected===1&&!isEditable}" @click="qaItemSave(index)">
                    <div class="circle" style="margin:0">
                        <i :class="[{'active':questionOpt.isSelected===1}]" />
                    </div>
                    <span>{{ setabc(index) }}、{{ questionOpt.label }}</span>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="vote-result" v-for="(questionOpt,index) in questionData.questionOpts" :key="index">
                <p class="title">{{questionOpt.label}}{{questionOpt.isSelected==1?'（'+$t("questionaire.Selected")+'）':''}}</p>
                <div class="result-details">
                    <van-slider v-model="questionOpt.votePercent" :bar-height="12" inactive-color="#DDDFE6" active-color="#3F9EFF">                   
                    </van-slider>
                    <div class="result-text">
                        <span>
                            {{questionOpt.voteQty||0}}{{$t("questionaire.ticket")}}
                        </span>
                        <span>
                            {{questionOpt.votePercent}}%
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { generateBig_1 } from '@/utils/generate26Letter'
export default {
    name:"voteChoiceQuestions",
    props: {
        isPreview: {
            type: Boolean,
            default: false
        },
        isEditable: {
            type: Boolean,
            default: true
        },
        isAdditional: {
            type: Boolean,
            default: false
        },
        questionData: Object,
        questionIndex: Number,
        questionTotal: Number,
        afterAnswered: Function
        // isSubmit: Number
    },
    data(){
        return{

        }
    },
    methods:{
        setabc(index) {
            const ary = generateBig_1()
            return ary[index]
        },
        qaItemSave(index) {
            if (!this.isEditable) {
                return
            }
            for (let i = 0; i < this.questionData.questionOpts.length; i++) {
                if (i === index) {
                this.questionData.questionOpts[i].isSelected = 1
                } else {
                this.questionData.questionOpts[i].isSelected = 0
                }
            }

            this.afterAnswered(this.questionData)
        },
    }
}
</script>

<style>

</style>