<template>
  <div>
    <p class="title">
      <span v-if="!isAdditional&&questionTotal>1">
        <i class="questionIndex"> {{ questionIndex + 1 }}</i>
        <i class="questionTotal">/{{ questionTotal }}</i>
      </span>
      <span v-else :alt="$t('questionaire.superadditionTopic')"></span>
      {{ questionData.question }}
      <b v-if="questionData.isOptional == 0" class="must-notice">*</b>
    </p>
<!--    <div class="vas-tip">-->
<!--      1-2分表示轻微疼痛，3分表示渐强疼痛，尚能忍受，4-5分表示强烈疼痛，难以忍受-->
<!--    </div>-->
    <div class="vas-outer">
      <div class="vas-slider">
        <van-slider v-model="questionData.inputAnswer" :disabled="!isEditable" :max="5" :min="0" :step="1" bar-height="5px" inactive-color="#BBD8F1" active-color="#BBD8F1" @change="answerValue">
          <template #button>
            <div class="custom-button">
              <span class="custom-button-value">{{ questionData.inputAnswer }}
              </span>
              <span class="custom-button-blue">
                {{ questionData.inputAnswer }}
              </span>

            </div>
          </template>
        </van-slider>
      </div>

      <div class="vas-top">
        <span v-for="j in questionData.vasDesc" :key="j" :title="j">{{ j }}</span>
      </div>

      <ul class="qn-vas-list">
        <li v-for="(item,i) in (questionData.vasScore+1)" :key="i">
          <span class="circle" :style="{background:'url('+steBg(i)+') no-repeat center'}" />
          <i>{{ i }}</i>
        </li>
      </ul>
    </div>
  </div>

</template>
<script>
import vas0 from '@/assets/images/vas/5-0.png'
import vas1 from '@/assets/images/vas/5-1.png'
import vas2 from '@/assets/images/vas/5-2.png'
import vas3 from '@/assets/images/vas/5-3.png'
import vas4 from '@/assets/images/vas/5-4.png'
import vas5 from '@/assets/images/vas/5-5.png'
export default {
  name: 'Vas5Question',

  props: {
    isPreview: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    isAdditional: {
      type: Boolean,
      default: false
    },
    questionData: Object,
    questionIndex: Number,
    questionTotal: Number,
    afterAnswered: Function
    // isSubmit: Number
  },
  data() {
    return {
      isSubmit: 1,
      list: [vas0, vas1, vas2, vas3, vas4, vas5]
    }
  },
  created() {
    try {
      this.questionData.inputAnswer = Number(this.questionData.inputAnswer)
    } catch {
      this.questionData.inputAnswer = 0
    }
    this.answerValue(0);
  },
  methods: {
    steBg(i) {
      return this.list[i]
    },
    answerValue(value) {
      if (!this.isEditable) {
        return
      }
      this.questionData.inputAnswer = value
      this.afterAnswered(this.questionData)
    }
  }
}
</script>

<style lang='scss' scoped>
.vas-tip{
  border-bottom:.05rem solid #eaeaea;
  color:#666;
  margin-bottom:.5rem;
  font-size:.5rem;
  padding-bottom:.5rem;
}
.vas-outer {
  width: 100%;
  margin-top:1.25rem;
  .vas-slider{
    padding:.5rem 0;
    .custom-button {
      position: relative;
      .custom-button-value{
        display: inline-block;
        width: 1.3rem;
        color:#fff;
        font-size: .5rem;
        line-height: 0.9rem;
        border-radius: 0.15rem;
        background-color: #3387FD;
        text-align: center;
        position: absolute;
        top: -1.25rem;

      }
      .custom-button-value:after{
        box-sizing: content-box;
        width: 0;
        height: 0;
        position: absolute;
        top: .75rem;
        right:50%;
        margin-right: -0.25rem;
        border-bottom: 0.35rem solid transparent;
        border-top: 0.35rem solid #3387FD;
        border-left: 0.25rem solid transparent;
        border-right: 0.25rem solid transparent;
        display: block;
        content:'';
        z-index:10
      }
      .custom-button-blue{
        display: inline-block;
        width: 1rem;
        color: #3387FD;
        font-size: .5rem;
        line-height: 1rem;
        text-align: center;
        background-color: #3387FD;
        border-radius: 5rem;
      }
    }
  }
  .qn-vas-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    li {
      margin:0;
      width:20%;
      span{
      display: block;
      width:95%;
      height:.5rem;
     }
      i{
        display: block;
        font-size: 0.4rem;
        text-align: center;
        color:#666666;
      }
    }
    .active {
      color:#fff;
      background: #3387FD;
    }
  }

  .vas-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.1rem;
    span {
      cursor: pointer;
      font-size:.5rem;
      color: #999999;
    }
  }
  // .click-ev {
  //   position: absolute;
  //   width: 75%;
  //   left: 130px;
  //   top: 0;
  //   bottom: 0;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  //   li {
  //     height: 60px;
  //   }
  // }
}
</style>
